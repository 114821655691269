import React from 'react';
import './WowButton.scss';

interface WowButtonProps {
    onClick(): void,
    title: string,
    submit?: boolean,
    disable?: boolean
}

export default class WowButton extends React.Component<WowButtonProps> {
    render() {
        return(
            this.props.submit ?
                <button className={`submit-button ${this.props.disable && "disabled"}`} type="submit" disabled={this.props.disable}>{this.props.title}</button>
            :
            <div className="button-container">
                <div className="button-parent" onClick={this.props.onClick}>
                    <span className="button-title">{this.props.title}</span>
                </div>
            </div>
        )
    }
}