import React from "react";
import './SidebarElement.scss';
import { getActivePage, setPageActive } from "../../../functions";
import { Page } from "../../../pages";

interface ISidebarElement {
    text: string,
    page?: Page,
    callbackClick(): void,
    extLink?: string
}

export default class SidebarElement extends React.Component<ISidebarElement> {

    sideBarClick() {
        this.props.callbackClick();
        this.props.extLink ? window.open(this.props.extLink, '_blank') : setPageActive(this.props.page!);
    }

    render() {
        const { text } = this.props;
        return(
            <div className="sidebar-element-container" onClick={() => this.sideBarClick()}>
                <div className={`sidebar-element-inner-container ${this.props.page === getActivePage() && "active"}`}>
                    <span className={`text ${this.props.page === getActivePage() && "active"}`}>{text}</span>
                </div>
            </div>
        )
    }
}