export default `
<b>Angaben gemäß § 5 TMG</b>
Cristina Rückels</br>
Wonder of Wedding</br>
Cristina Rückels Weddings&Events</br>
Emil-Ruschenbaum-Weg 4a</br>
58239 SchwerteAngaben gemäß § 5 TMG</br></br>
<b>Kontakt</b>
Telefon: +49 (0) 1514 0392517</br>
E-Mail: info@wonder-of-wedding.de</br></br>
<b>EU-Streitschlichtung</b>
Die Europäische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS) bereit:</br>
https://ec.europa.eu/consumers/odr.</br>
Unsere E-Mail-Adresse finden Sie oben im Impressum.</br></br>
<b>Verbraucherstreitbeilegung/Universalschlichtungsstelle</b>
Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren vor einer</br>
Verbraucherschlichtungsstelle teilzunehmen.</br></br>
<b>Haftung für Inhalte</b>
Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte auf diesen Seiten nach den</br>
allgemeinen Gesetzen verantwortlich. Nach §§ 8 bis 10 TMG sind wir als Diensteanbieter jedoch nicht</br>
verpflichtet, übermittelte oder gespeicherte fremde Informationen zu überwachen oder nach Umständen zu</br>
forschen, die auf eine rechtswidrige Tätigkeit hinweisen.</br>
Verpflichtungen zur Entfernung oder Sperrung der Nutzung von Informationen nach den allgemeinen</br>
Gesetzen bleiben hiervon unberührt. Eine diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt der</br>
Kenntnis einer konkreten Rechtsverletzung möglich. Bei Bekanntwerden von entsprechenden</br>
Rechtsverletzungen werden wir diese Inhalte umgehend entfernen.</br></br>
<b>Urheberrecht</b>
Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten unterliegen dem deutschen</br>
Urheberrecht. Die Vervielfältigung, Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb der</br>
Grenzen des Urheberrechtes bedürfen der schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers.</br>
Downloads und Kopien dieser Seite sind nur für den privaten, nicht kommerziellen Gebrauch gestattet.</br>
Soweit die Inhalte auf dieser Seite nicht vom Betreiber erstellt wurden, werden die Urheberrechte Dritter</br>
beachtet. Insbesondere werden Inhalte Dritter als solche gekennzeichnet. Sollten Sie trotzdem auf eine</br>
Urheberrechtsverletzung aufmerksam werden, bitten wir um einen entsprechenden Hinweis. Bei</br>
Bekanntwerden von Rechtsverletzungen werden wir derartige Inhalte umgehend entfernen.</br></br>
<b>Quelle:</b>
https://www.e-recht24.de`