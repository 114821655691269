import React from 'react';
import { Page } from '../../pages';
import NavigationElement from './NavigationElement/NavigationElement';
import './Header.scss';
import { getActivePage } from '../../functions';
import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/Close';

interface IHeader {
    smallScreen?: boolean, 
    drawerClick: () => void,
    drawerOpen: boolean
}

export default class Header extends React.Component<IHeader> {
    render() {
        const backgroundColorChoose: string = (getActivePage() === Page.Home || getActivePage() === Page.Galerie || getActivePage() === Page.Impressum) ? "home" : (getActivePage() === Page.Leistungen) ? "leistungen" : "contact"
        return(
            this.props.smallScreen ?
            <div className={`header-small-drawer ${this.props.drawerOpen && "open"}`} onClick={() => this.props.drawerClick()}>
                {this.props.drawerOpen ? 
                <CloseIcon fontSize="large" style={{color: backgroundColorChoose === "leistungen" ? 'white' : 'black'}} />
                :
                <MenuIcon fontSize="large" style={{color: backgroundColorChoose === "leistungen" ? 'white' : 'black'}} />
                }
            </div>
            :
            <div className={`header-row-container ${backgroundColorChoose}`}>
                <div className="header-logo-container">
                    <span className={`logo-text ${backgroundColorChoose}`}>Wonder of &nbsp;</span>
                    <span className={`logo-text2 ${backgroundColorChoose}`}>Wedding</span>
                </div>
                <div className="header-navigation">
                    <NavigationElement title="Home" page={Page.Home} />
                    <NavigationElement title="Leistungen" page={Page.Leistungen} />
                    <NavigationElement title="Galerie" page={Page.Galerie} />
                    <NavigationElement title="Kontakt" page={Page.Kontakt} />
                    <NavigationElement title="Impressum" page={Page.Impressum} />
                    <NavigationElement title="Shop" extLink={Page.Etsy} />
                </div>
            </div>
        )
    }
}