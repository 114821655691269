import React, { useState } from 'react';
import './Kontakt.scss';
import './Input.scss';
import { useForm } from '@formspree/react';
import WowButton from '../../components/WowButton/WowButton';
import * as EmailValidator from 'email-validator';

const Kontakt: React.FC = () => {
  const [state, handleSubmit] = useForm("mjvjakdv");
  const [nameValid, setNameValid] = useState(false);
  const [nachnameValid, setNachnameValid] = useState(false);
  const [emailValid, setEmailValid] = useState(false);
  const [messageValid, setMessageValid] = useState(false);

  const checkValid = (type: "name" | "nachname" | "email" | "message", value: string) => {
    switch (type) {
      case "email": {
        if (stringLengthCheck(value)) {
          setEmailValid(EmailValidator.validate(value));
        }
        break;
      }
      case "name": {
        setNameValid(stringLengthCheck(value))
        break;
      }
      case "nachname": {
        setNachnameValid(stringLengthCheck(value))
        break;
      }
      case "message": {
        setMessageValid(stringLengthCheck(value))
        break;
      }
    }
  }

  const stringLengthCheck = (value: string) => {
    if (value.length > 0) {
      return true;
    } else {
      return false;
    }
  }

  if (state.succeeded) {
    return (
      <div className="contact-container">
        <div className="form-container thanks">
          <span className="form-thanks">Vielen Dank für deine Anfrage. Ich werde dir schnellstmöglich antworten.</span>
        </div>
      </div>
    )
  }
  return (
    <div className="contact-container">
      <form onSubmit={handleSubmit}>
        <div className="form-container">
          <div className="form-header">
            <span className="form-header-text">Kontakt</span>
          </div>
          <div className="input-container">
            <div className="row input-first-row">
              <input
                className="custom-input left"
                id="vorname"
                type="text"
                name="vorname"
                placeholder="Name"
                onChange={(event) => { 
                  checkValid("name", event.target.value);
                }}
              />
              <input
                className="custom-input"
                id="nachname"
                type="text"
                name="nachname"
                placeholder="Nachname"
                onChange={(event) => { 
                  checkValid("nachname", event.target.value);
                }}
              />
            </div>
            <input
              className="custom-input long"
              id="email"
              type="email"
              name="email"
              placeholder="Email Addresse"
              onChange={(event) => { 
                checkValid("email", event.target.value);
              }}
            />
            <textarea
              className="custom-input long margin textarea"
              id="message"
              name="message"
              placeholder="Ihre Nachricht"
              onChange={(event) => { 
                checkValid("message", event.target.value);
              }}
            />
          </div>
          <div className="contact-button-container">
            <WowButton 
              title="Senden" 
              onClick={() => console.log()} 
              submit 
              disable={!nachnameValid || !nameValid || !emailValid || !messageValid}
            />
          </div>
        </div>
      </form>
    </div>
  );
}
export default Kontakt;