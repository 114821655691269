// const local = {
//     API: 'http://localhost:3009',
//     STRIPE_KEY: 'pk_test_2URTspFOdqmFYuOhSXzSbs9E',
//     ENV: 'local'
// };
export interface IConfig {
    API: string,
    ENV: string
}
const local: IConfig = {
    API: 'http://localhost:8082/v1',
    ENV: 'development'
};
const config: IConfig = local
export let Environment: IConfig = config