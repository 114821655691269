import React from 'react';
import impressumText from '../../assets/text/impressum';
import './Impressum.scss';

export default class Impressum extends React.Component {
    render() {
        return(
            <div className="impressum-container">
                <div className="impressum-text" dangerouslySetInnerHTML={{__html: impressumText}} />
            </div>
        )
    }
}