import React from "react";
import { Logos } from "../../assets/assets";
import { Page } from "../../pages";
import './Sidebar.scss';
import SidebarElement from "./SidebarElement/SidebarElement";

export default class Sidebar extends React.Component<{callback: () => void}> {
    render() {
        return (
            <div className="sidebar-container">
                <div className="side-wow-logo-container">
                    <img className="wow-logo" src={Logos.wow_logo} alt="wonder-of-wedding" />
                </div>
                <SidebarElement text="Home" page={Page.Home} callbackClick={() => this.props.callback()} />
                <SidebarElement text="Leistungen" page={Page.Leistungen} callbackClick={() => this.props.callback()} />
                <SidebarElement text="Galerie" page={Page.Galerie} callbackClick={() => this.props.callback()} />
                <SidebarElement text="Kontakt" page={Page.Kontakt} callbackClick={() => this.props.callback()} />
                <SidebarElement text="Impressum" page={Page.Impressum} callbackClick={() => this.props.callback()} />
                <SidebarElement text="Shop" extLink={Page.Etsy} callbackClick={() => this.props.callback()} />
            </div>
        )
    }
}