import React from 'react';
import { Colors, Icons, Images, LongText, Text } from '../../assets/assets';
import BackgroundImage from '../../components/BackgroundImage/BackgroundImage';
import FabButton from '../../components/FabButton/FabButton';
import Header from '../../components/Header/Header';
import PictureText from '../../components/PictureText/PictureText';
import leistungen01Text from '../../assets/text/leistungen01Text';
import leistungen02Text from '../../assets/text/leistungen02Text';
import leistungen03Text from '../../assets/text/leistungen03Text';
import leistungen04Text from '../../assets/text/leistungen04Text';
import leistungen05Text from '../../assets/text/leistungen05Text';
import galerie01 from '../../assets/text/galerie01';
import './Leistungen.scss';
import WowButton from '../../components/WowButton/WowButton';
import { setPageActive } from '../../functions';
import { Page } from '../../pages';
import LeistungenIconText from '../../components/LeistungenIconText/LeistungenIconText';

interface LeistungenProps {
    smallScreen: boolean,
    drawerClick: () => void,
    drawerOpen: boolean
}

export default class Leistungen extends React.Component<LeistungenProps> {

    private switchId: string = "leistungen-section-two"

    render() {
        return(
            <>
                <FabButton id={this.switchId}/>
                <div className="leistungen-container">
                    <BackgroundImage url={Images.leistungenBack}>
                        <Header smallScreen={this.props.smallScreen} drawerClick={() => this.props.drawerClick()} drawerOpen={this.props.drawerOpen} />
                        <section className="leistungen-section-one-container" />
                    </BackgroundImage>
                    <section className="leistungen-section-two-container" id={this.switchId}>
                        <PictureText 
                            title={Text.leistungen}
                            subtitle={Text.full_service_planung}
                            text={leistungen01Text}
                            picture={Images.leistungen01}
                            backgroundColor={Colors.beige}
                        />
                        <PictureText 
                            subtitle={Text.teil_planung}
                            text={leistungen02Text}
                            picture={Images.leistungen02}
                            leftPicture
                        />
                        <PictureText 
                            subtitle={Text.wedding_day_managerin}
                            text={leistungen03Text}
                            picture={Images.leistungen03}
                            backgroundColor={Colors.greyBlue}
                            additional={<WowButton title={Text.contact} onClick={() => setPageActive(Page.Kontakt)} />}
                        />
                        <PictureText 
                            subtitle={Text.event_planung}
                            text={leistungen04Text}
                            picture={Images.leistungen04}
                            leftPicture
                        />
                        <PictureText 
                            subtitle={Text.vertrauen}
                            text={leistungen05Text}
                            picture={Images.leistungen05}
                            backgroundColor={Colors.beige}
                        />
                    </section>
                    <section className="leistungen-section-three-container column">
                        <pre>{galerie01}</pre>
                        <div className="leistungen-section-three-parent row">
                            <div className="leistungen-section-three-column column">
                                <LeistungenIconText 
                                    icon={Icons.kennenlernen}
                                    title={Text.kennenlernen}
                                    text={LongText.kennenlernen}
                                />
                                <LeistungenIconText 
                                    icon={Icons.budgetkalkulation}
                                    title={Text.budgetkalkulation}
                                    text={LongText.budgetkalkulation}
                                />
                                <LeistungenIconText 
                                    icon={Icons.locationsuche}
                                    title={Text.locationsuche}
                                    text={LongText.locationsuche}
                                />
                            </div>
                            <div className="column">
                                <LeistungenIconText 
                                    icon={Icons.designkonzept}
                                    title={Text.designkonzept}
                                    text={LongText.designkonzept}
                                />
                                <LeistungenIconText 
                                    icon={Icons.dienstleisterplanung}
                                    title={Text.dienstleisterplanung}
                                    text={LongText.dienstleisterplanung}
                                />
                                <LeistungenIconText 
                                    icon={Icons.hochzeitstag}
                                    title={Text.hochzeitstag}
                                    text={LongText.hochzeitstag}
                                />
                            </div>
                        </div>
                    </section>
                </div>
            </>
        )
    }
}