import React from 'react';
import { Logos } from '../../assets/assets';
import './Footer.scss';

export default class Footer extends React.Component {
    render() {
        return(
            <div className="footer-container">
                <img src={Logos.wow_logo} alt="my_logo" />
            </div>
        )
    }
}