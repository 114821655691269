export let Keys = {
    navigation_event: 'com.test.web.navigation_event',
    drawer_event: 'com.test.web.sidebar.event',
    side_bar_event: 'com.test.web.active.event',
    open_search_user_event: 'com.test.web.open_search_user',
}

export let Colors = {
    beige: "#E7E0D6",
    greyBlue: "#EAEAEA"
}

export let Images = {
    c_background: require('./images/c_background.png'),
    home01: require('./images/01.jpg'),
    home02: require('./images/home02.jpg'),
    home03: require('./images/home03.jpg'),
    home04: require('./images/home01.jpg'),
    home05: require('./images/home04.jpg'),
    leistungenBack: require('./images/02.jpg'),
    leistungen01: require('./images/leistungen01.jpg'),
    leistungen02: require('./images/leistungen02.jpg'),
    leistungen03: require('./images/leistungen03.jpg'),
    leistungen04: require('./images/leistungen04.jpg'),
    leistungen05: require('./images/leistungen05.jpg'),
}

export const GalerieImages = {
    galerie1: require('./images/galerie/galerie1.jpg'),
    galerie2: require('./images/galerie/galerie2.jpg'),
    galerie3: require('./images/galerie/galerie3.jpg'),
    galerie4: require('./images/galerie/galerie4.jpg'),
    galerie5: require('./images/galerie/galerie5.jpg'),
    galerie6: require('./images/galerie/galerie6.jpg'),
    galerie7: require('./images/galerie/galerie7.jpg'),
    galerie8: require('./images/galerie/galerie8.jpg'),
    galerie9: require('./images/galerie/galerie9.jpg'),
    galerie10: require('./images/galerie/galerie10.jpg'),
    galerie11: require('./images/galerie/galerie11.jpg'),
    galerie12: require('./images/galerie/galerie12.jpg'),
    galerie13: require('./images/galerie/galerie13.jpg'),
    galerie14: require('./images/galerie/galerie14.jpg'),
    galerie15: require('./images/galerie/galerie15.jpg'),
    galerie16: require('./images/galerie/galerie16.jpg'),
    galerie17: require('./images/galerie/galerie17.jpg'),
    galerie18: require('./images/galerie/galerie18.jpg'),
    galerie19: require('./images/galerie/galerie19.jpg'),
    galerie20: require('./images/galerie/galerie20.jpg'),
    galerie21: require('./images/galerie/galerie21.jpg'),
    galerie22: require('./images/galerie/galerie22.jpg'),
    galerie23: require('./images/galerie/galerie23.jpg'),
    galerie24: require('./images/galerie/galerie24.jpg'),
    galerie25: require('./images/galerie/galerie25.jpg'),
    galerie26: require('./images/galerie/galerie26.jpg'),
    galerie27: require('./images/galerie/galerie27.jpg'),
    galerie28: require('./images/galerie/galerie28.jpg'),
    galerie29: require('./images/galerie/galerie29.jpg'),
    galerie30: require('./images/galerie/galerie30.jpg'),
    galerie31: require('./images/galerie/galerie31.jpg')
}

export let Icons = {
    budgetkalkulation: require('./icons/budgetkalkulation.svg'),
    designkonzept: require('./icons/designkonzept.svg'),
    dienstleisterplanung: require('./icons/dienstleisterplanung.svg'),
    hochzeitstag: require('./icons/hochzeitstag.svg'),
    kennenlernen: require('./icons/budgetkalkulation.svg'),
    locationsuche: require('./icons/budgetkalkulation.svg')
}

export let Logos = {
    wow_logo: require('./images/logo.svg')
}

export let Text = {
    full_service: "Full-Service",
    wedding_events: "Weddings & Events",
    hey_i_am: "Hey! Ich bin Cristina!",
    leistungen: "Leistungen",
    full_service_planung: "Full-Service-Hochzeitsplanung",
    teil_planung: "Teil-Hochzeitsplanung",
    wedding_day_managerin: "Wedding-Day-Managerin",
    event_planung: "Eventplanung",
    vertrauen: "Vertrauen",
    contact: "Kontakt",
    budgetkalkulation: "Budgetkalkulation",
    designkonzept: "Designkonzept",
    dienstleisterplanung: "Dienstleisterplanung",
    hochzeitstag: "Der Hochzeitstag",
    kennenlernen: "Kennenlernen",
    locationsuche: "Locationsuche"
}

export let LongText = {
    budgetkalkulation: "Während der gesamten Planung ist diese unsere Grundlage.",
    designkonzept: "Dekoration, Blumen und Einladungskarten werden aufeinander abgestimmt",
    dienstleisterplanung: "Eine Liste mit verschiedenen Dienstleistern wird erstellt, aus denen ihr natürlich wählen könnt ",
    hochzeitstag: "Ihr werdet jede Minute, vom Styling bis zur Verabschiedung, genießen und ich kümmere mich um die Koordination. ",
    kennenlernen: "Um einen der wichtigsten Tage eures Lebens planen zu können, ist es wichtig eine Vertrauensbasis aufzubauen.",
    locationsuche: "Um ein perfektes Designkonzept erstellen zu können, ist es wichtig die Grundlagen dafür zu schaffen."
}
