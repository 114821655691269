import React from 'react';
import { getActivePage, setPageActive } from '../../../functions';
import { Page } from '../../../pages';
import './NavigationElement.scss';

export default class NavigationElement extends React.Component<{title: string, page?: Page, extLink?: string}> {
    openPage() {
        this.props.extLink ? window.open(this.props.extLink, '_blank') : setPageActive(this.props.page!);
    }

    render() {
        const activePage: Page | undefined = getActivePage(); 
        const backgroundColorChoose: string | boolean = getActivePage() === Page.Leistungen && "leistungen"
        return(
            <div className="navigation-element-container" onClick={() => this.openPage()}>
                <span className={`navigation-element-title ${activePage && activePage === this.props.page && "active"} ${backgroundColorChoose}`}>{this.props.title}</span>
            </div>
        )
    }
}