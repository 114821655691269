import React from 'react';
import { Images, Text } from '../../assets/assets';
import BackgroundImage from '../../components/BackgroundImage/BackgroundImage';
import FabButton from '../../components/FabButton/FabButton';
import home01Text from '../../assets/text/home01Text';
import home02Text from '../../assets/text/home02Text';
import './Home.scss';
import WowButton from '../../components/WowButton/WowButton';
import { setPageActive } from '../../functions';
import { Page } from '../../pages';

export default class Home extends React.Component {

    private switchId: string = "home-section-three"

    render() {
        return (
            <>
                <FabButton id={this.switchId} />
                <div className="home-container">
                    <BackgroundImage url={Images.c_background} home>
                        <section className="home-section-one-container">
                            <div className="home-section-one-text-container">
                                <div>
                                    <span className="home-section-one-title">Wonder of &nbsp;</span>
                                    <span className="home-section-one-title2">Wedding</span>
                                </div>
                                <span className="home-section-one-subtitle">Wo du hingehst, da will ich auch hingehen.</span>
                                <span className="home-section-one-subtitle">Wo du bleibst, da bleibe ich auch.</span>
                            </div>
                        </section>
                    </BackgroundImage>
                    <BackgroundImage url={Images.home01}>
                        <section className="home-section-two-container" />
                    </BackgroundImage>
                    <section className="home-section-three-container centering" id={this.switchId}>
                        <div className="row home-section-three-parent">
                            <div className="column home-section-three-column-one">
                                <div className="home-section-three-text-container">
                                    <span className="home-section-three-overtitle" aria-label={Text.full_service}>{Text.full_service}</span>
                                    <span className="home-section-three-title" aria-label={Text.wedding_events}>{Text.wedding_events}</span>
                                    <pre className="home-section-three-text" aria-label="what-i-can-do">{home01Text}</pre>
                                    <WowButton
                                        onClick={() => setPageActive(Page.Leistungen)}
                                        title="Leistungen"
                                    />
                                </div>
                            </div>
                            <div className="column home-section-three-column-two">
                                <div className="column home-section-three-image-container">
                                    <img className="first-img" src={Images.home02} alt="happy-bride" />
                                    <img className="second-img" src={Images.home03} alt="rings" />
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="home-section-four-container centering">
                        <div className="row home-section-four-parent">
                            <div className="column home-section-four-column-two">
                                <div className="column home-section-four-image-container">
                                    <img className="first-img" src={Images.home05} alt="decorate" />
                                    <img className="second-img" src={Images.home04} alt="its_me" />
                                </div>
                            </div>
                            <div className="column home-section-four-column-one">
                                <div className="home-section-four-text-container">
                                    <span className="home-section-four-overtitle" aria-label={Text.hey_i_am}>{Text.hey_i_am}</span>
                                    <pre className="home-section-four-text" aria-label="who-i-am">{home02Text}</pre>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </>
        )
    }
}