import * as LocalStorage from "local-storage"; //https://github.com/bevacqua/local-storage
import * as uuid from "uuid"

export const API_URL =
	"http://Mawa-env.eba-pefcvvmw.us-east-1.elasticbeanstalk.com/v1";
export const API_LOCALHOST = "http://localhost:8082/v1";

export const KEY_SESSION = "com.wow.persistent.auth.SESSION";
export const KEY_USER = "com.wow.persistent.auth.USER";
export const KEY_API_URL = "com.wow.persistent.api.url";
export const StandardDevice = {
	device_uuid: uuid.v4(),
	app_version: "1",
	build_version: "1",
	debug: true,
	language: "de",
};

export interface ViewProps {
	mobileScreen: boolean,
	tabletScreen: boolean
}

export const testENV = () => {
}

export function remove(key: string) {
	LocalStorage.remove(key);
}
export function clearAll() {
	LocalStorage.clear();
}

export function save(key: string, value: string) {
	LocalStorage.set(key, value);
}
export function get(key: string): string {
	return LocalStorage.get(key);
}

export function watch(
	key: string,
	onChange: (value: string, oldValue: string) => void
) {
	LocalStorage.on(key, onChange);
}
export function unwatch(
	key: string,
	onChange: (value: string, oldValue: string) => void
) {
	LocalStorage.off(key, onChange);
}
