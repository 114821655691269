import { Page } from "./pages";
import { EventEmitter } from "events";
import { Keys } from './assets/assets';

export const navigationEventEmitter = new EventEmitter();

export function getActivePage(fallbackPage?: Page) {
    const websitePath = window.location.pathname || "";
    var activePage: Page | undefined = fallbackPage;

    Object.keys(Page).forEach(key => {
        if (
            websitePath === Page[key] ||
            (activePage === fallbackPage &&
                Page[key].endsWith("/*") &&
                websitePath.startsWith(Page[key].substr(0, Page[key].length - 2)))
        ) {
            activePage = Page[key];
        }
    });

    return activePage;
}

export function setPageActive(
    page: Page,
    passive?: boolean,
    data?: {},
    urlParamters?: string
) {
    var url: string = page || "/home";
    if (urlParamters) {
        if (url.endsWith("/*")) {
            url = url.substr(0, url.length - 1);
        }
        if (!url.endsWith("/")) {
            url += "/";
        }
        url += urlParamters.startsWith("/") ? urlParamters.substr(1) : urlParamters;
    }
    if (window.location.pathname === url) {
        return	// was called hundreds of times unnecessarily 
    }
    window.history.pushState(data || {}, window.document.title, url);

    //emit navigation event
    navigationEventEmitter.emit(Keys.navigation_event, { url, page, passive });
    navigationEventEmitter.emit(Keys.side_bar_event)
    navigationEventEmitter.emit(Keys.drawer_event, "close")
    goToTop()
}

export function goToTop() {
    window.scrollTo({
        top: 0,
        left: 0
    })
}
