import React from 'react';
import './BackgroundImage.scss';

export default class BackgroundImage extends React.Component<{url: string, home?: boolean}>{
    render() {
        return (
            <div className={`${this.props.home ? "background-image home" : "background-image"}`} style={{backgroundImage: `url(${this.props.url})`}}>
                {this.props.children}
            </div>
        )
    }
}