import { KEY_SESSION, KEY_USER } from './PersistantData';
import * as Store from './PersistantData'

export function setSession(session: any) {
    Store.save(KEY_SESSION, JSON.stringify(session))
}

export function getSession(): any {
    try {
        const serialized = Store.get(KEY_SESSION)
        if (serialized) {
            // return new Session(JSON.parse(serialized))
            return JSON.parse(serialized)
        }
    }
    catch (err) {
        //
    }
    return undefined
}

export function clearSession() {
    Store.remove(KEY_SESSION)
}

export function setUser(user: any) {
    Store.save(KEY_USER, JSON.stringify(user))
}

export function getUser(): any {
    try {
        const user = Store.get(KEY_USER)
        if (user) {
            return JSON.parse(user)
        }
    }
    catch (err) {

    }
    return undefined
}

export function getAuthToken() {
    let actualSession = getSession()

    if (actualSession) {
        return actualSession.session_token
    } else {
        return false
    }
}

export function getAuthHeader(): {} {
    let auth = getAuthToken()
    return {
        "Authorization": `Bearer ${auth}`
    }
}

export function getPutHeader(image?: string): {} {
    let auth = getAuthToken()
    return {
        "Authorization": `Bearer ${auth}`,
        // "Content-Type": image ? image : 'application/json'
        "Content-Type": 'application/json'
    }
}

export function getPutImage(): {} {
    let auth = getAuthToken()
    return {
        "Authorization": `Bearer ${auth}`,
        // "Content-Type": image ? image : 'application/json'
        "Content-Type": 'multipart/form-data'
    }
}

export function getRegHeader(): {} {
    return {
        "Content-Type": 'application/json'
    }
}

export function isSessionValid(): boolean {
    let actualSession: any = getSession()
    return (actualSession && actualSession.session_token) ? true : false
}

export function setApiUrl(apiUrl: string) {
    Store.save(Store.KEY_API_URL, apiUrl)
}

export function getApiUrl() {
    return Store.get(Store.KEY_API_URL)
    // return "http://Mawa-env.eba-pefcvvmw.us-east-1.elasticbeanstalk.com/v1"
}