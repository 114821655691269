import React from 'react';
import './PictureText.scss';

interface IPictureTextProps {
    picture: any,
    title?: string,
    subtitle: string,
    text: any,
    leftPicture?: boolean,
    backgroundColor?: any,
    additional?: React.ReactNode
}

export default class PictureText extends React.Component<IPictureTextProps> {
    render() {
        return (
            <div className={`picture-text-container ${this.props.leftPicture && "revert"}`} style={this.props.backgroundColor && { backgroundColor: this.props.backgroundColor }}>
                {!this.props.leftPicture ?
                    <>
                        <div className="picture-text-left-text-column">
                            <div className="picture-text-text-column">
                                {this.props.title &&
                                    <span className="title">{this.props.title}</span>
                                }
                                <span className="subtitle">{this.props.subtitle}</span>
                                <pre className="text">{this.props.text}</pre>
                                {this.props.additional &&
                                    <div>
                                        {this.props.additional}
                                    </div>
                                }
                            </div>
                        </div>
                        <div className="picture-text-right-picture-column">
                            <div className="picture-text-picture-column">
                                <img src={this.props.picture} alt="" />
                            </div>
                        </div>
                    </>
                    :
                    <>
                        <div className="picture-text-right-picture-column">
                            <div className="picture-text-picture-column">
                                <img src={this.props.picture} alt="" />
                            </div>
                        </div>
                        <div className="picture-text-left-text-column">
                            <div className="picture-text-text-column">
                                {this.props.title &&
                                    <span className="title">{this.props.title}</span>
                                }
                                <span className="subtitle">{this.props.subtitle}</span>
                                <pre className="text">{this.props.text}</pre>
                            </div>
                        </div>
                    </>
                }

            </div>
        )
    }
}