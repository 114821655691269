import React from 'react';
import { GalerieImages } from '../../assets/assets';
import './Galerie.scss';

const GaleriePictures = [
    GalerieImages.galerie1,
    GalerieImages.galerie2,
    GalerieImages.galerie3,
    GalerieImages.galerie4,
    GalerieImages.galerie5,
    GalerieImages.galerie6,
    GalerieImages.galerie7,
    GalerieImages.galerie8,
    GalerieImages.galerie9,
    GalerieImages.galerie10,
    GalerieImages.galerie11,
    GalerieImages.galerie12,
    GalerieImages.galerie13,
    GalerieImages.galerie14,
    GalerieImages.galerie15,
    GalerieImages.galerie16,
    GalerieImages.galerie17,
    GalerieImages.galerie18,
    GalerieImages.galerie19,
    GalerieImages.galerie20,
    GalerieImages.galerie21,
    GalerieImages.galerie22,
    GalerieImages.galerie23,
    GalerieImages.galerie24,
    GalerieImages.galerie25,
    GalerieImages.galerie26,
    GalerieImages.galerie27,
    GalerieImages.galerie28,
    GalerieImages.galerie29,
    GalerieImages.galerie30,
    GalerieImages.galerie31
]

export default class Galerie extends React.Component {

    firstElement() {
        return (
            <div className="galerie-element first">
                <div className="first-element-text-container">
                    <span className="instagram">Instagram</span>
                    <span className="bildergalerie">Bildergalerie</span>
                </div>
            </div>
        )
    }

    render() {
        return(
            <div className="galerie-container">
                <div className="galerie-inner row">
                    {this.firstElement()}
                    {GaleriePictures.slice(0).reverse().map((image, index) => {
                        return (
                            <div className="galerie-element" style={{backgroundImage: `url(${image})`}} key={`galerie-${index}`} />
                        )
                    })}
                </div>
            </div>
        )
    }
}