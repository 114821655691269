import React from "react";
import './LeistungenIconText.scss';

interface LeistungenIconTextProps {
    icon: any,
    title: string,
    text: string
}

export default class LeistungenIconText extends React.Component<LeistungenIconTextProps> {
    render() {
        return(
            <div className="leistungen-icon-text-container row">
                <div className="leistungen-icon-text-icon">
                    <img src={this.props.icon} alt={this.props.title} />
                </div>
                <div className="leistungen-icon-text-text-container">
                    <span className="title">{this.props.title}</span>
                    <span className="text">{this.props.text}</span>
                </div>
            </div>
        )
    }
}