import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import App from './App'
import { Page } from './pages';

window.onload = function() {
	ReactDOM.render(
		<BrowserRouter>
			<Switch>
				<Route path='/:page' component={App} />
	
				{/*FallBack*/}
				{/* <Route component={Home} /> */}
				<Route render={() => {
					return <Redirect to={Page.Home} />
				}} />
			</Switch>
		</BrowserRouter>,
		document.getElementById('root')
	);
}



/*declare global {
	interface Array<T> {
		unique(property?: string): Array<T>;
	}
}
Array.prototype.unique = function <T>(this: Array<T>, property?: string) {
	let s = new Set();
	return this.filter((value: any, index, self) => {
		if (property && s.has(value[property])) {
			return false;
		} else if (property) {
			s.add(value[property])
			return true;
		}
		if (s.has(value)) {
			return false;
		}
		s.add(value);
		return true;
	})
}*/