import React, { Suspense, useEffect } from 'react';

//Components
import { WowRoute } from './components/WowRoute';
import { Page } from './pages';
import { navigationEventEmitter } from './functions';
import { Keys } from './assets/assets';
import Drawer from '@material-ui/core/Drawer';
import { setApiUrl } from './SessionCompat';
import { Environment } from './env';
import Backdrop from '@material-ui/core/Backdrop';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

//Pages
import Home from './pages/Home/Home';
import LoadingPage from './components/LoadingPage/LoadingPage';
import Footer from './components/Footer/Footer';
import Leistungen from './pages/Leistungen/Leistungen';
import Galerie from './pages/Galerie/Galerie';
import Impressum from './pages/Impressum/Impressum';
import Kontakt from './pages/Kontakt/Kontakt';
import Header from './components/Header/Header';
import Sidebar from './components/Sidebar/Sidebar';

const App: React.FC = () => {

	const [, forceUp] = React.useState(0);
	const [smallDrawer, setSmallDrawer] = React.useState(false)
	const [drawerOpen, setDrawerOpen] = React.useState(false);
	const forceUpdate = () => forceUp(a => a + 1)

	const useStyles = makeStyles((theme: Theme) =>
		createStyles({
			backdrop: {
				zIndex: 1,
				color: '#fff',
			},
		}),
	);

	setApiUrl(Environment.API)

	useEffect(() => {
		const onWindowStatePopped = (event: PopStateEvent) => {
			forceUpdate();		//re-render component
		}
		window.addEventListener("popstate", onWindowStatePopped);
		return () => window.removeEventListener("popstate", onWindowStatePopped);
	}, [])

	useEffect(() => {
		const listener = (data) => {
			forceUpdate();		//re-render component
		}
		navigationEventEmitter.addListener(Keys.navigation_event, listener)
		return () => {
			navigationEventEmitter.removeListener(Keys.navigation_event, listener)
		}
	}, [])

	useEffect(() => {
		const listener = (data) => {
			if (window.screen.width <= 1050) {
				setSmallDrawer(true);
			} else {
				setSmallDrawer(false);
			}
		}
		window.addEventListener("resize", listener);
		window.addEventListener("load", listener);
		return () => {
			window.removeEventListener("resize", listener);
			window.removeEventListener("load", listener);
		}
	})

	useEffect(() => {
		if (window.screen.width <= 1050) {
			setSmallDrawer(true);
		} else {
			setSmallDrawer(false);
		}
	}, [])

	const drawerClick = (drawerOpen: boolean) => {
		setDrawerOpen(drawerOpen);
		document.body.style.overflow = drawerOpen ? "hidden" : "unset"
	}

	const classes = useStyles();
	return (
		<React.Fragment>
			<Drawer
				variant="permanent"
				PaperProps={{ style: { overflow: "hidden", border: 'none', zIndex: 2, backgroundColor: 'transparent' } }}
				anchor="left"
			>
				{smallDrawer && drawerOpen && <Sidebar callback={() => drawerClick(false)} />}
			</Drawer>
			<Backdrop className={classes.backdrop} open={drawerOpen} onClick={() => setDrawerOpen(!drawerOpen)} />
			{window.location.pathname !== Page.Leistungen && <Header smallScreen={smallDrawer} drawerClick={() => drawerClick(!drawerOpen)} drawerOpen={drawerOpen}/>}
			<MainPage smallScreen={smallDrawer} drawerOpen={drawerOpen} drawerClick={() => drawerClick(!drawerOpen)} />
			<Footer />
		</React.Fragment>
	)
}

export default App

interface IMainPage {
	smallScreen: boolean, 
	drawerOpen: boolean,
	drawerClick: () => void
}

const MainPage: React.FC<IMainPage> = (props) => {

	return (
		<Suspense fallback={<LoadingPage />}>
			<WowRoute route={Page.Home}>
				<Home />
			</WowRoute>
			<WowRoute route={Page.Leistungen}>
				<Leistungen smallScreen={props.smallScreen} drawerOpen={props.drawerOpen} drawerClick={() => props.drawerClick()} />
			</WowRoute>
			<WowRoute route={Page.Galerie}>
				<Galerie />
			</WowRoute>
			<WowRoute route={Page.Auth}>
				<Galerie />
			</WowRoute>
			<WowRoute route={Page.Impressum}>
				<Impressum />
			</WowRoute>
			<WowRoute route={Page.Kontakt}>
				<Kontakt />
			</WowRoute>
			<WowRoute route={undefined} fallbackPage={Page.Home}>
				<Home />
			</WowRoute>
		</Suspense>
	)

}