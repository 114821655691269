import React from 'react';
import Fab from '@material-ui/core/Fab';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import './FabButton.scss';

interface FabButtonProps {
    id: string
}

export default class FabButton extends React.Component<FabButtonProps> {

    fabClick() {
        const position: Element | null = document.querySelector('#'+this.props.id);
        position?.scrollIntoView({
            behavior: 'smooth',
            block: 'start'
        });
    }

    render() {
        return (
            <Fab aria-label="move" className="fab-button" onClick={() => this.fabClick()}>
                <KeyboardArrowDownIcon className="fab-arrow" />
            </Fab>
        )
    }
}