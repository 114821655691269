import React from "react";
import { Page } from "../pages";
import { getActivePage, setPageActive } from "../functions";

export const WowRoute: React.SFC<{
    route: Page[] | Page | undefined;
    fallbackPage?: Page;
}> = props => {
    const page = getActivePage();
    var visible = false;
    if (page !== undefined && Array.isArray && Array.isArray(props.route)) {
        if (props.route.includes(page)) {
            visible = true;
        }
    } else if (props.route === page) {
        visible = true;
    }
    if (!page && props.fallbackPage) {
        window.location.pathname = props.fallbackPage;
        setPageActive(Page.Home);
    }
    return (
        <>
            {visible && props.children}
        </>
    );
};